import { ModalProvider } from 'shared/ui/modal'
import styled from 'styled-components/macro'

export const StyledModal = styled(ModalProvider)`
  .ant-modal-content {
    width: 320px;
  }

  .ant-modal-content .ant-modal-body {
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 16px;
  }

  .ant-modal-content .ant-modal-header {
    padding: 16px 16px 0;
  }
  & .ant-modal-title {
    font-size: 13px;
    line-height: 18px;
  }
`

export const Wrapper = styled.div`
  position: relative;

  & .ant-modal-title {
    font-size: 13px;
  }
`

export const Label = styled.label`
  display: flex;
  width: 100%;
  flex-direction: column;
  white-space: nowrap;
`

export const LabelTitle = styled.div`
  color: var(--color-text-3);
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 4px;
`

export const GroupElems = styled.div`
  display: grid;
  gap: 16px;
  grid-template-columns: min-content 1fr;
`

export const ButtonElems = styled.div`
  display: flex;
  justify-content: flex-end;
  column-gap: 8px;
  padding-top: 8px;
`

export const ErrorMessage = styled.div`
  margin-top: 8px;
  padding: 8px;
  background-color: var(--color-red);
  border-radius: 5px;
  font-size: 13px;
  color: var(--color-text-1);
`
